import { Call, Device } from '@twilio/voice-sdk';
import { TwilioError } from '@twilio/voice-sdk/es5/twilio/errors';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useinitiateVirtualDirectoryRequestMutation } from '../../apollo';
import { getErrorCode } from '../../helpers';
import { useAlertModalState } from '../../hooks';
import AlertModal from '../AlertModal';
import CallScreen from '../CallScreen';

export type CallState =
  | 'UNKNOWN'
  | 'INITIATED'
  | 'CONNECTED'
  | 'RECORDING'
  | 'ON_HOLD'
  | 'APPROVED'
  | 'DENIED'
  | 'NO_ANSWER'
  | 'ERROR';

interface VirtualDirectoryContactRowProps {
  accessPointId: string;
  userId?: string;
  contactName: string;
  subtext?: string | null;
  disabled?: boolean;
  actionContent?: React.ReactNode;
}

export const VirtualDirectoryContactRow: React.FC<VirtualDirectoryContactRowProps> = ({
  accessPointId,
  userId,
  contactName,
  subtext,
  disabled,
  actionContent,
}) => {
  const navigate = useNavigate();

  const [inProgressCall, setInProgressCall] = useState<Call | null>(null);
  const [callState, setCallState] = useState<CallState>('UNKNOWN');
  const { alertModalState, setAlertModalState } = useAlertModalState();
  const [device, setDevice] = useState<Device | null>(null);
  const [inProgress, setInProgress] = useState(false);
  const [initiateVirtualDirectoryRequest] = useinitiateVirtualDirectoryRequestMutation({
    variables: {
      input: {
        accessPointId,
        contactUserId: userId,
      },
    },
  });

  useEffect(() => {
    if (inProgressCall) {
      inProgressCall.on('disconnect', async (call) => {
        device?.destroy();
        setDevice(null);
        setInProgressCall(null);
        setInProgress(false);
      });

      // This is custom user defined event. This is Twilio public beta
      inProgressCall.on('messageReceived', (message) => {
        const status = message?.content?.status;
        setCallState(status);
      });
    }

    if (device) {
      device.on('error', (twilioError: TwilioError, call) => {
        if (twilioError.code === 20104) {
          device.destroy();
        }
      });
    }

    if (!device && inProgressCall === null) {
      if (callState === 'APPROVED') {
        setTimeout(() => {
          navigate(`/qr/${accessPointId}`);
        }, 200);
      } else if (callState === 'DENIED') {
        setAlertModalState({
          isOpen: true,
          title:'Sorry, access has been denied',
          // @TODO: What message if they were denied by staff?
          content:'For assistance, please contact the leasing office.',
        });
      } else if (callState === 'ERROR') {
        setAlertModalState({
          isOpen: true,
          title:'Sorry, an unexpected error occurred',
          content:'For assistance, please contact the leasing office.',
        });
      }
    }
    // eslint-disable-next-line
  }, [accessPointId, inProgressCall, callState, device]);

  return (
    <div className="row-wrapper display-flex-wrapper">
      <div className="name-wrapper display-flex-wrapper">
        <span><b>{contactName}</b></span>
        {subtext && <span>{subtext}</span>}
      </div>
      <div className="phone-action-wrapper">
        <button
          className="button-with-icon"
          style={{ color: '#0076cc' }}
          disabled={disabled}
          onClick={async (e) => {
            if (inProgress || disabled) {
              return;
            }

            try {
              setInProgress(true);
              await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

              const { data } = await initiateVirtualDirectoryRequest();
              const twilioToken = data ? data.initiateVirtualDirectoryRequest : null;

              if (twilioToken) {
                const twilioDevice = new Device(twilioToken);
                setDevice(twilioDevice);

                const call = await twilioDevice.connect({});

                setCallState('INITIATED');
                setInProgressCall(call);
              }
            } catch (err) {
              const error = err as Error;
              const errorCode = getErrorCode(error);

              if (error.message.includes('Permission denied')) {
                setAlertModalState({
                  isOpen: true,
                  title: 'Unable to access Microphone',
                  content: 'Please allow access to your microphone to initiate the phone call.',
                });
              } else {
                setAlertModalState({
                  isOpen: true,
                  title: errorCode === 'UNAUTHORIZED' || errorCode === 'TOO_MANY_REQUESTS'
                    ? 'Service Unavailable'
                    : 'Unexpected Error',
                  content: error.message,
                });
              }
              setInProgress(false);
            }
          }}
        >
          {actionContent || (
            <>
              <img src="/images/phone-blue.png" alt="" className="phone-icon" />
              <span><b>Call</b></span>
            </>
          )}
        </button>
      </div>
      <AlertModal
        {...alertModalState}
        closeModal={() => {
          setAlertModalState({ isOpen: false, title: null, content: null });
          setCallState('UNKNOWN');
        }}
      />
      {inProgressCall &&
        <CallScreen
          callState={callState}
          name={contactName}
          onDigitPress={(digit) => {
            inProgressCall.sendDigits(digit);
          }}
          onEndCall={() => {
            inProgressCall.disconnect();
          }}
        />}
    </div>
  );
};

export default VirtualDirectoryContactRow;

import React from 'react';
import { CHIRP_APP_DOWNLOAD_ANDROID_URL, CHIRP_APP_DOWNLOAD_IOS_URL } from '../../config';

export const ChirpAppDownloadLinks: React.FC = () => {
  return (
    <div className="app-badges">
      <a href={CHIRP_APP_DOWNLOAD_IOS_URL} className="download-badge-image">
        <img src="/images/badge-app-store.png" alt="Chirp Access - Apple App Store" />
      </a>
      <a href={CHIRP_APP_DOWNLOAD_ANDROID_URL} className="download-badge-image">
        <img src="/images/badge-google-play.png" alt="Chirp Access - Google Play" />
      </a>
    </div>
  );
};

export default ChirpAppDownloadLinks;

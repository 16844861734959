import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SupportPhoneNumber from '../SupportPhoneNumber';

interface CopyRequestAccessUrlProps {
  openEulaModal: () => void;
  hasAcceptedEula: boolean;
  propertyName: string;
  supportPhoneNumber?: string | null;
  requestAccessUrl: string;
  isVirtualDirectoryEnabled: boolean;
}

const CopyRequestAccessUrl = (props: CopyRequestAccessUrlProps) => {
  const {
    openEulaModal,
    hasAcceptedEula,
    propertyName,
    supportPhoneNumber,
    requestAccessUrl,
    isVirtualDirectoryEnabled,
  } = props;

  const copyButton = (
    <button
      className="blue-button w-inline-block override-copy-button"
      onClick={hasAcceptedEula ? undefined : openEulaModal}
    >
      <h1 className="h2">Copy</h1>
    </button>
  );

  return (
    <>
      <div className="content">
        <h2 className="h2 text-dark">Welcome to {propertyName}!</h2>
        <div className="p text-mid-gray">
          {isVirtualDirectoryEnabled
            ? 'Request temporary access from a resident by sending them the link below, or use the Property Directory.'
            : 'Request temporary access from a resident by sending them the link below.'}
          {!isVirtualDirectoryEnabled &&
            <SupportPhoneNumber supportPhoneNumber={supportPhoneNumber} />
          }
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <input
          readOnly
          className="shared-url"
          value={requestAccessUrl}
          onClick={hasAcceptedEula ? undefined : openEulaModal}
        />
        {!hasAcceptedEula && copyButton}
        {hasAcceptedEula && (
          <CopyToClipboard text={requestAccessUrl}>
            {copyButton}
          </CopyToClipboard>
        )}
      </div>
    </>
  );
};

export default CopyRequestAccessUrl;

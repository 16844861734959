import delay from 'delay';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ApolloError } from '@apollo/client';
import { TimetableRuleState } from '@chirp/access-verification';
import useInterval from '@use-it/interval';

import {
  usegetAccessPointQuery,
  usevendorPinUserRoleQuery,
  VendorUserRoleFragment as VendorUserRole,
} from '../../apollo';
import {
  AlertModal,
  LoadingSpinner,
  QRScanner,
  TapToUnlockDial,
  VendorPinAccessVerification,
} from '../../components';
import { Timetable } from '../../components/Timetable';
import { CHECK_ACCESS_INTERVAL_MS } from '../../config';
import {
  getAvailabilityDescription, getAvailabilityState, getErrorMessage, isDateAllowedOnTimetable
} from '../../helpers';
import { useAnonymousUserContext } from '../../hooks';
import VendorAccessHeader from './VendorAccessHeader';

interface ModalState {
  isOpen: boolean;
  isCloseable: boolean;
  content: JSX.Element | string | null;
  title: string | null;
}

interface VendorAccessState {
  vendorUserRole: VendorUserRole | null | undefined;
  skipUnlockAPView: boolean;
  showQRScanner: boolean;
  accessExpired: boolean;
  modalState: ModalState;
}

const VendorAccess: React.FC = () => {
  const accessPointId = useParams().accessPointId as string;
  const { isVendorPinClaimExpired } = useAnonymousUserContext();
  const [vendorPinClaimExpired, setVendorPinClaimExpired] = useState(isVendorPinClaimExpired());

  const accessTime = new Date();

  const [state, setState] = useState<VendorAccessState>({
    vendorUserRole: null,
    skipUnlockAPView: true,
    showQRScanner: false,
    accessExpired: false,
    modalState: {
      isOpen: false,
      isCloseable: true,
      content: null,
      title: null,
    },
  });

  const { skipUnlockAPView, accessExpired, vendorUserRole, modalState } = state;

  const { data, refetch, loading: loadingVendorUserRole } = usevendorPinUserRoleQuery({
    skip: !accessPointId,
    onCompleted: (vendorData) => {
      const userRole = vendorData?.vendorPinUserRole;

      setState({
        ...state,
        vendorUserRole: userRole,
        skipUnlockAPView: !!userRole,
      });
    },
  });

  const { data: accessPointsData, loading: loadingAccessPoint } = usegetAccessPointQuery({
    skip: !accessPointId,
    variables: { accessPointId },
    onError: (err) => {
      setState(prevState => ({
        ...prevState,
        modalState: {
          isOpen: true,
          isCloseable: false,
          title: 'Unable to access entry point',
          content: getErrorMessage(err),
        },
      }));
    },
  });

  const accessPoint = accessPointsData?.accessPoint;
  const vendorPinUserRole = data?.vendorPinUserRole;
  const vendorRoleExpiresAt = vendorPinUserRole?.expiresAt;
  const vendorRoleActivatesAt = vendorPinUserRole?.activatesAt;
  const vendorRoleRecurringSchedule = vendorPinUserRole?.schedule;
  const propertyFeatureFlags = accessPoint?.property?.featureFlags || [];
  const isVirtualDirectoryEnabled = propertyFeatureFlags.includes('VIRTUAL_DIRECTORY');

  const expirationTime = vendorRoleExpiresAt ?
    moment(vendorRoleExpiresAt).format('MMM D, h:mma') :
    null;

  useInterval(() => {
    setVendorPinClaimExpired(isVendorPinClaimExpired());

    setState({
      ...state,
      accessExpired: vendorRoleExpiresAt ?
        moment(new Date()).isSameOrAfter(vendorRoleExpiresAt) : false,
    });
  }, CHECK_ACCESS_INTERVAL_MS);

  const alertModal = modalState.isOpen ? (
    <AlertModal
      {...modalState}
      closeModal={() => {
        if (modalState.isCloseable) {
          setState({
            ...state,
            modalState: {
              ...modalState,
              isOpen: false,
            },
          });
        }
      }}
    />
  ) : null;

  if (!modalState.isCloseable) {
    return alertModal;
  }

  if (loadingAccessPoint || loadingVendorUserRole || !accessPoint) {
    return <LoadingSpinner />;
  }

  if (!vendorPinUserRole || vendorPinClaimExpired || accessExpired) {
    return (
      <VendorPinAccessVerification
        isVirtualDirectoryEnabled={isVirtualDirectoryEnabled}
        onVerified={async () => {
          try {
            const { data } = await refetch();

            setState({ ...state, vendorUserRole: data?.vendorPinUserRole });
          } catch (error) {
            setState({
              ...state,
              modalState: {
                isOpen: true,
                isCloseable: false,
                title: 'Unexpected Error',
                content: getErrorMessage(error as ApolloError),
              },
            });
          }
        }}
      />
    );
  }

  if (vendorUserRole && !accessExpired) {
    const property = accessPoint.property;
    const propertyName = property?.name;
    const { schedule: accessPointSchedule } = accessPoint.publicAccessPointRoles.VENDOR_PIN;
    const { timezone } = property;

    const apScheduleAllowed = isDateAllowedOnTimetable(accessTime, accessPointSchedule, timezone);
    const accessScheduleAllowed = isDateAllowedOnTimetable(accessTime, vendorRoleRecurringSchedule, timezone);
    const apAvailability = getAvailabilityDescription(accessTime, accessPointSchedule, timezone);
    const apAvailableState = getAvailabilityState(accessTime, accessPointSchedule, timezone);

    if (!skipUnlockAPView) {
      return (
        <div className="guest-content-wrapper">
          <VendorAccessHeader
            title={accessPoint.name}
            subTitle={propertyName}
            image={accessPoint.image}
          />
          <TapToUnlockDial
            accessPointId={accessPointId}
            accessTimeout={accessPoint.accessTimeout}
            isAvailable={apScheduleAllowed}
            hasAccess={accessScheduleAllowed}
            isAutomaticallyUnlocked={apAvailableState === TimetableRuleState.UNLOCKED}
            availability={apAvailability}
            bottomContent={
              <div className="content-bottom" style={{ alignItems: 'flex-start' }}>
                <img src="/images/icon-alert.png" alt="" className="icon-bottom" />
                <div className="p text-dark text-bold">
                  <div className="p text-dark text-bold">Access granted!</div>
                  <div className="spacer"></div>
                    {(vendorRoleRecurringSchedule || vendorRoleExpiresAt) &&
                      <div>Your Access Schedule:</div>
                    }
                    {expirationTime && !vendorRoleRecurringSchedule &&
                      <div>{moment(vendorRoleActivatesAt).format('MMMM D, h:mma')} - {moment(vendorRoleExpiresAt).format('MMMM D, h:mma')}</div>
                    }
                    {vendorRoleRecurringSchedule &&
                      <Timetable timetable={vendorRoleRecurringSchedule} />
                    }
                </div>
              </div>
            }
            onFinishedUnlock={async () => {
              await delay(accessPoint.accessTimeout + 8000);
              setState({ ...state, skipUnlockAPView: true });
            }}
          />
          {alertModal}
        </div>
      );
    }

    return (
      <div className="guest-content-wrapper">
        <VendorAccessHeader
          title={propertyName}
          subTitle='Vendor Access'
          image={property.image}
        />
        <div className="content guest-content">
          <h2 className="h1 text-dark">Access Granted!</h2>
          <div className="spacer"></div>
          <div className="p">You have access to {propertyName}.</div>
          <div className="spacer"></div>
          <div className="p">
            See a Chirp QR code? Open the QR Scanner below to unlock the entry point.
          </div>
          {(vendorRoleRecurringSchedule || expirationTime) &&
            <>
              <div className="spacer"></div>
              <div className="p">Your Access Schedule:</div>
            </>
          }
          {expirationTime && !vendorRoleRecurringSchedule &&
            <div className="p">{moment(vendorRoleActivatesAt).format('MMMM D, h:mma')} - {moment(vendorRoleExpiresAt).format('MMMM D, h:mma')}</div>
          }
          {vendorRoleRecurringSchedule &&
            <div className="p"><Timetable timetable={vendorRoleRecurringSchedule} /></div>
          }
        </div>
        <div className="spacer"></div>
        {(state.showQRScanner) &&
          <QRScanner
            expiresAt={vendorRoleExpiresAt}
            onClickBack={() => setState({ ...state, showQRScanner: false })}
            headerSubtext={vendorRoleExpiresAt && moment(vendorRoleExpiresAt).format('MMM D, h:mm A')}
            headerText={vendorRoleExpiresAt && 'Access Expires:'}
            onFinishScanning={() => setState({ ...state, showQRScanner: false })}
            roleKey="VENDOR_PIN"
          />
        }
        <button
          className="blue-button justify-content-center"
          onClick={() => setState({ ...state, showQRScanner: true })}
        >
          <h1 className="h2">Open QR Code Scanner</h1>
        </button>
        {alertModal}
      </div>
    );
  }

  return <LoadingSpinner />;
};

export default VendorAccess;

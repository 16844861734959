// tslint:disable:max-line-length
import React from 'react';
import { ChirpAppDownloadLinks, Header, LearnMoreAboutChirp } from '../../components';
import Footer from '../../components/Footer';

const Resident: React.FC = () => {
  return (
    <div className="page-wrapper">
      <Header defaultHeader={true} />
      <div className="content display-flex-wrapper">
        <h2 className="h2-dark">Resident Access</h2>
        <div className="p">Residents have permanent access via the Chirp app. Download Chirp to get started.</div>
        <ChirpAppDownloadLinks />
        <div className="p">If you are having issues with your Chirp account please contact your leasing office staff.</div>
      </div>
      <LearnMoreAboutChirp />
      <Footer />
    </div>
  );
};

export default Resident;

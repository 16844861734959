import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export function selectError(error: ApolloError | Error) {
  const { graphQLErrors, networkError } = error as ApolloError;

  if (graphQLErrors && graphQLErrors.length) {
    return graphQLErrors[0];
  }

  if (networkError) {
    // @TODO: Is this still an issue?
    // NOTE: In some cases the correct error is inside of networkError.result.errors
    // Sometimes a unique constraint violation from a GraphQL error might show up here
    // https://github.com/apollographql/apollo-client/issues/2810#issuecomment-654226300
    return networkError;
  }

  return error;
}

export function getErrorCode(error: GraphQLError | Error) {
  const selectedError = selectError(error);

  return (selectedError as GraphQLError)?.extensions?.code as string | null | undefined;
}

export function getErrorMessage(error: ApolloError | Error, fallbackMessage?: string): string {
  const selectedError = selectError(error);

  return selectedError.message || fallbackMessage || 'An unexpected error occurred';
}

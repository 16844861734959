import React from 'react';
import { CHIRP_MARKETING_URL } from '../../config';

export const LearnMoreAboutChirp: React.FC = () => {
  return (
    <a
      href={CHIRP_MARKETING_URL}
      target="_blank"
      rel="noopener noreferrer"
      className="learn-more-about-chirp w-inline-block"
    >
      <h2 className="h3-white">Learn more about Chirp</h2>
      <img src="images/icon-chevron-right.png" alt="Learn More" className="icon-12px" />
    </a>
  );
};

export default LearnMoreAboutChirp;

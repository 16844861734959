import { TimetableRuleState } from '@chirp/access-verification';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PublicAccessPointRoles,
  usegetAccessPointQuery,
  useunlockAccessPointMutation,
} from '../../apollo';
import {
  getAvailabilityDescription,
  getAvailabilityState,
  getErrorMessage,
  isDateAllowedOnTimetable,
} from '../../helpers';
import AlertModal from '../AlertModal';
import UnlockDial from '../UnlockDial';
import animationData from '../UnlockDial/unlocking-animation-white.json';

interface QRScannerDialProps {
  accessPointId: string;
  expiresAt?: string | null;
  onClose: () => void;
  roleKey: keyof PublicAccessPointRoles;
}

const QRScannerDial: React.FC<QRScannerDialProps> = (props) => {
  const navigate = useNavigate();

  const [isRestricted, setIsRestricted] = useState(false);
  const [isUnlocking, setIsUnlocking] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [availability, setAvailability] = useState('');
  const [hasAccess, setHasAccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { accessPointId, expiresAt, onClose, roleKey } = props;

  const [unlockAccessPoint] = useunlockAccessPointMutation({
    variables: { accessPointId },
  });

  const { loading, data } = usegetAccessPointQuery({
    skip: !accessPointId,
    variables: { accessPointId },
    onCompleted: async ({ accessPoint: loadedAccessPoint }) => {
      try {
        const { schedule } = loadedAccessPoint.publicAccessPointRoles[roleKey];
        const { timezone } = loadedAccessPoint.property;

        const accessTime = new Date();

        if (expiresAt && !moment(accessTime).isBefore(expiresAt)) {
          // Simulate API error when access is expired
          throw new Error('You do not have access to this entry point');
        }

        if (isDateAllowedOnTimetable(accessTime, schedule, timezone)) {
          await unlockAccessPoint();
          setIsRestricted(false);
          setIsAvailable(true);
          setIsUnlocking(true);
        } else {
          setTimeout(() => {
            onClose();
          }, 5000);
        }

        setHasAccess(true);
        setAvailability(getAvailabilityDescription(accessTime, schedule, timezone));
      } catch (error) {
        if (
          accessPointId &&
          getErrorMessage(error as any) === 'You do not have access to this entry point'
        ) {
          // Access has likely expired if the user receives this error message.
          // Let's redirect them to the callbox killer page to request new access.
          navigate(`/qr/${accessPointId}`);
        } else {
          setIsRestricted(true);
          setHasAccess(false);
          setErrorMessage(getErrorMessage((error as any), 'Unable to unlock entry point'));
        }
      }
    },
    onError: (error) => {
      setErrorMessage(getErrorMessage(error, 'Unable to unlock entry point'));
    },
  });

  const accessPoint = data ? data.accessPoint : null;

  if (errorMessage) {
    return (
      <AlertModal
        isOpen
        title="Unable to unlock entry point"
        content={errorMessage}
        closeModal={() => {
          setErrorMessage(null);
          onClose();
        }}
      />
    );
  }

  if (loading) {
    return null;
  }

  if (accessPoint) {
    const { name, property } = accessPoint;
    const { schedule } = accessPoint.publicAccessPointRoles[roleKey];
    const { timezone } = property;

    const accessTimeout = accessPoint.accessTimeout || 5000;
    const availabilityState = getAvailabilityState(new Date(), schedule, timezone);

    return (
      <div className="qr-scanner-dial">
        <div className="qr-scanner-dial-wrapper disabled-button">
          <UnlockDial
            animationData={animationData}
            accessTimeout={accessTimeout}
            availability={availability}
            hasAccess={hasAccess}
            header={hasAccess ? name : ''}
            isAutomaticallyUnlocked={availabilityState === TimetableRuleState.UNLOCKED}
            isAvailable={isAvailable}
            isRestricted={isRestricted}
            isUnlocking={isUnlocking}
            onComplete={onClose}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default QRScannerDial;

import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function formatPhoneNumber(phoneNumber: string | null | undefined): string {
  if (!phoneNumber) {
    return '';
  }

  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

  return parsedPhoneNumber ? parsedPhoneNumber.formatNational() : phoneNumber;
}

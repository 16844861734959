import React from 'react';
import { Link } from 'react-router-dom';
import { REALPAGE_EULA_URL, REALPAGE_PATENT_MARKING, REALPAGE_PRIVACY_POLICY_URL } from '../../config';

export const Footer: React.FunctionComponent = () => {
  return (
    <div className="footer-wrapper content">
      <div className="footer">
        <Link
          to={REALPAGE_PATENT_MARKING}
          target="_blank"
          rel="noopener noreferrer"
        >
          Patent Marking
        </Link>
        <Link
          to={REALPAGE_EULA_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          EULA Agreement
        </Link>
        <Link
          to={REALPAGE_PRIVACY_POLICY_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
      </div>
    </div>
  );
};

export default Footer;

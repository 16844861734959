import React from 'react';
import Modal from 'react-modal';
import SupportPhoneNumber from '../SupportPhoneNumber';

interface VirtualDirectoryInfoModalProps {
  closeModal: () => void;
  isOpen: boolean;
  supportPhoneNumber?: string | null;
}

Modal.setAppElement('#root');

export const VirtualDirectoryInfoModal = (props: VirtualDirectoryInfoModalProps) => {
  const { closeModal, isOpen, supportPhoneNumber } = props;

  return (
    <Modal
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
      isOpen={isOpen}
    >
      <div className="modal">
        <div className="modal-text">
          <h1 className="h1 text-white">Directory Help</h1>
          <div className="p text-white">
            <ol className="order-list">
              <li className="spacer">
                <b>Look up the person you want to contact</b> using their name or unit number.
              </li>
              <li className="spacer">
                <b>Tap the Call button</b> to request access through a phone call.
              </li>
              <li className="spacer">
                <b>Listen to the phone prompts</b> to know when your access is approved!
              </li>
            </ol>
          </div>
          <div className="p text-white">
            <SupportPhoneNumber supportPhoneNumber={supportPhoneNumber} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VirtualDirectoryInfoModal;

import React, { useEffect } from 'react';
import {
  ChirpAppDownloadLinks,
  Header,
  LearnMoreAboutChirp,
  LoadingSpinner,
} from '../../components';
import {
  CHIRP_APP_DOWNLOAD_ANDROID_URL,
  CHIRP_APP_DOWNLOAD_IOS_URL,
  CHIRP_APP_DOWNLOAD_MARKETING_URL,
} from '../../config';
import { getDeviceType } from '../../helpers';

const Home: React.FC = () => {
  const deviceType = getDeviceType();

  useEffect(() => {
    if (deviceType === 'iOS') {
      window.location.replace(CHIRP_APP_DOWNLOAD_IOS_URL);
    } else if (deviceType === 'Android') {
      window.location.replace(CHIRP_APP_DOWNLOAD_ANDROID_URL);
    } else {
      window.location.replace(CHIRP_APP_DOWNLOAD_MARKETING_URL);
    }
  }, [deviceType]);

  if (deviceType === 'iOS' || deviceType === 'Android') {
    // Attempt to redirect user outside of browser to iOS/Android app download.
    // Render download links on web page in case they choose not to be redirected.
    return (
      <>
        <Header defaultHeader={true} />
        <div className="content">
          <h2 className="h2-dark">Download the Chirp Access App</h2>
          <div className="p">
            Automated, secure access that empowers residents and guests
            to get where they want to be as quickly and easily as possible.
          </div>
          <ChirpAppDownloadLinks />
        </div>
        <LearnMoreAboutChirp />
      </>
    );
  }

  return <LoadingSpinner />; // Redirect to marketing URL in browser
};

export default Home;

import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { GRAPHQL_SERVER_SUBSCRIPTION_URL, GRAPHQL_SERVER_URL } from '../config';
import { clearLocalStorage } from '../hooks';

function getHeaders() {
  const accessToken = localStorage.getItem('chirpAccessToken');

  if (accessToken) {
    return {
      authorization: `${accessToken}`,
    };
  }

  return {};
}

const httpLink = createHttpLink({
  uri: GRAPHQL_SERVER_URL,
});

const wsLink = new GraphQLWsLink(createClient({
  url: GRAPHQL_SERVER_SUBSCRIPTION_URL,
  lazy: true,
  shouldRetry: () => true,
  connectionParams: () => getHeaders(),
}));

// Since anonymous access tokens don't expire and therefore don't get renewed, we will use
// this safeguard in case the old one becomes invalid (e.g., if we change our JWT secret).
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  let isAccessTokenInvalid = false;

  if (graphQLErrors) {
    for (const graphQLError of graphQLErrors) {
      if (
        graphQLError.extensions &&
        graphQLError.extensions.code === 'UNAUTHENTICATED'
      ) {
        isAccessTokenInvalid = true;
        break;
      }
    }
  }

  if (networkError && networkError.message.includes('Invalid authorization token')) {
    isAccessTokenInvalid = true;
  }

  if (isAccessTokenInvalid) {
    // "Log out" the anonymous user
    clearLocalStorage();
    window.location.reload();
  }
});

const authMiddleware = setContext(async () => ({
  headers: getHeaders(),
}));

const splittedLink = split(
  ({ query }: any) => {
    // https://github.com/apollographql/apollo-client/issues/3090#issuecomment-379804727
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const apolloLink = ApolloLink.from([errorLink, authMiddleware, splittedLink]);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: apolloLink,
});

import { useState } from 'react';

interface AlertModalState {
  isOpen: boolean;
  content: string | null;
  title: string | null;
  queryError?: boolean;
}

export function useAlertModalState(initialState?: Partial<AlertModalState>) {
  const [alertModalState, setAlertModalState] = useState<AlertModalState>({
    isOpen: false,
    title: null,
    content: null,
    queryError: false,
    ...initialState,
  });

  return { alertModalState, setAlertModalState };
}

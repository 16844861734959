import React from 'react';
import { FadeLoader } from 'react-spinners';

export const LoadingSpinner: React.FC = () => {
  return (
    <div className="display-flex-wrapper" style={{ alignItems: 'center', marginTop: 30 }}>
      <FadeLoader color="#0076cc" />
    </div>
  );
};

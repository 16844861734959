import useLocalStorage from '@rehooks/local-storage';
import { useState } from 'react';

export function useEulaModalState() {
  const [acceptedEulaAt] = useLocalStorage<string>('acceptedEulaAt');
  const [isEulaModalOpen, setIsEulaModelOpen] = useState<boolean>(false);

  return {
    acceptedEulaAt,
    isEulaModalOpen,
    openEulaModal: () => setIsEulaModelOpen(true),
    closeEulaModal: () => setIsEulaModelOpen(false),
  };
}

import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { apolloClient } from './apollo';
import { browserRouter } from './routes/browserRouter';

const App: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <RouterProvider router={browserRouter} />
    </ApolloProvider>
  );
};

export default App;

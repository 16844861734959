import useLocalStorage from '@rehooks/local-storage';
import React from 'react';
import Modal from 'react-modal';
import { REALPAGE_EULA_URL, REALPAGE_PRIVACY_POLICY_URL } from '../../config';

interface EulaModalProps {
  closeModal: () => void;
  isOpen: boolean;
  onAcceptEula?: () => any | (() => Promise<any>);
}

Modal.setAppElement('#root');

export const EulaModal = (props: EulaModalProps) => {
  const { closeModal, isOpen, onAcceptEula } = props;
  const [, setAcceptedEulaAt] = useLocalStorage<string>('acceptedEulaAt');

  return (
    <Modal
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          border: 'none',
          borderRadius: '0',
        },
      }}
    >
      <div className="modal-agreement">
        <div className="modal-content-container">
          <div className="modal-text">
            <div className="spacer-5"></div>
            <div className="p text-white">
            By tapping Accept, you understand and agree to the terms in the&nbsp;
              <a
                href={REALPAGE_EULA_URL}
                target="_blank"
                rel="noopener noreferrer"
              >Smart Building End User License Agreement</a> and&nbsp;
              <a
                href={REALPAGE_PRIVACY_POLICY_URL}
                target="_blank"
                rel="noopener noreferrer"
              >Privacy Policy</a>.
            </div>
          </div>
        </div>
        <div className="button-container">
          <button
            className="gray-button-half w-inline-block"
            onClick={() => closeModal()}
          ><h1 className="h2">Decline</h1></button>
          <button
            className="blue-button-half w-inline-block"
            onClick={async () => {
              setAcceptedEulaAt(new Date().toISOString());
              closeModal();

              if (typeof onAcceptEula === 'function') {
                await onAcceptEula();
              }
            }}
          ><h1 className="h2">Accept</h1></button>
        </div>
      </div>
    </Modal>
  );
};

export default EulaModal;

import { TimetableRule } from '@chirp/access-verification';
import moment from 'moment-timezone';
import React from 'react';

const DAY_ABBREVIATIONS = [
  'M',
  'Tu',
  'W',
  'Th',
  'F',
  'Sa',
  'Su',
];

interface TimetableProps {
  timetable?: TimetableRule[] | null;
}

export const Timetable: React.FC<TimetableProps> = (props) => {
  const { timetable } = props;

  return (
    <>
      {timetable?.map(({ days, start, end }, index) => {
        const dayAbbreviations = days.map(day => DAY_ABBREVIATIONS[day - 1]).join(', ');

        const parsedStartTime = moment(start, 'HH:mm');
        const parsedEndTime = moment(end, 'HH:mm');

        const times = `${parsedStartTime.format('h:mma')} - ${parsedEndTime.format('h:mma')}`;

        return (
          <div
            key={index}
            style={{
              display: 'table-row',
              whiteSpace: 'nowrap',
              marginTop: 10,
            }}
          >
            <span style={{ display: 'table-cell', paddingRight: 15 }}>
              {dayAbbreviations}:&nbsp;
            </span>
            <span style={{ display: 'table-cell' }}>{times}</span>
          </div>
        );
      })}
    </>
  );
};

import delay from 'delay';
import React, { useRef, useState } from 'react';
import QrReader from 'react-qr-reader';
import { PublicAccessPointRoles } from '../../apollo';
import backArrow from '../../assets/back-arrow-icon.svg';
import { CHIRP_UNIVERSAL_LINK } from '../../config';
import ButtonIcon from '../ButtonIcon';
import QRScannerDial from '../QRScannerDial';
import styles from './styles';

interface QRScannerProps {
  expiresAt?: string | null;
  headerText?: string | null;
  headerSubtext?: string | null;
  onClickBack?: () => void;
  onFinishScanning?: (accessPointId: string) => void;
  hideDrawerAfterScan?: boolean;
  isPropertyMapVisible?: boolean;
  roleKey: keyof PublicAccessPointRoles;
}

interface Reader extends QrReader {
  els: {
    input: {
      value: string;
    },
  };
}

export const QRScanner: React.FC<QRScannerProps> = (props) => {
  const { expiresAt, isPropertyMapVisible, roleKey } = props;

  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [showUnlockDial, setShowUnlockDial] = useState<boolean>(false);
  const [legacyMode, setLegacyMode] = useState<boolean>(false);
  const [accessPointId, setAccessPointId] = useState<string | null>(null);
  const [scanError, setScanError] = useState<boolean>(false);
  const [isOpeningModule, setIsOpeningModule] = useState(true);

  const readerRef = useRef<Reader>(null);

  return (
    <div
      className={`${isOpeningModule ? styles.qrCodeScanOpen : styles.qrCodeScanClose} qr-scanner`}
    >
      <div className="header">
        <div className="headerLeft">
          <ButtonIcon
            icon={backArrow}
            className={''}
            onClick={async () => {
              setIsOpeningModule(false);
              await delay(500);

              if (typeof props.onClickBack === 'function') {
                props.onClickBack();
              }
            }}
            iconDimensions={{ x: '20px', y: '20px' }}
          />
        </div>
        <div className="headerRight">
          {props.headerText &&
            <div className="headerRightText">
              {props.headerText}
            </div>
          }
          {props.headerSubtext &&
            <div className="headerRightSubtext">
              {props.headerSubtext}
            </div>
          }
        </div>
      </div>
      <QrReader
        ref={readerRef}
        delay={1000}
        facingMode="environment"
        showViewFinder
        legacyMode={legacyMode}
        onLoad={() => {
          setIsScanning(true);
        }}
        onError={(err: Error) => {
          setLegacyMode(true);
          if (readerRef && readerRef.current) {
            readerRef.current.openImageDialog();
          }
        }}
        onScan={(data: string | null) => {
          if (!isScanning || accessPointId) return;

          if (legacyMode && !data) {
            setScanError(true);
          }

          if (data) {
            const { origin, pathname } = new URL(data);
            // e.g., https://access.chirpsystems.com
            if (origin === CHIRP_UNIVERSAL_LINK) {
              // e.g., /entry/12345 or /entry/d0e9352f-11c7-4758-abf6-0eea4a098605
              const [, , scannedAccessPointId] = pathname.split('/');

              if (scannedAccessPointId) {
                setIsScanning(false);
                setAccessPointId(scannedAccessPointId);
                setScanError(false);
                if (isPropertyMapVisible) {
                  if (typeof props.onFinishScanning === 'function') {
                    props.onFinishScanning(scannedAccessPointId);
                  }
                } else {
                  setShowUnlockDial(true);
                }
              } else {
                setScanError(true);
              }
            }
          }
        }}
      />
      <div
        className="qr-scanner-overlay"
        style={{ backgroundColor: legacyMode ? '#333' : `rgba(0, 0, 0, ${showUnlockDial ? '.8' : '.2'}` }}
      >
        {showUnlockDial && accessPointId
          ? <QRScannerDial
            accessPointId={accessPointId}
            expiresAt={expiresAt}
            onClose={() => {
              setShowUnlockDial(false);
              setIsScanning(true);
              setAccessPointId(null);
            }}
            roleKey={roleKey}
          />
          : legacyMode
            // Legacy Mode
            ? <div
              className="h2 qr-scanner-overlay-text"
              onClick={() => {
                if (readerRef && readerRef.current) {
                  readerRef.current.els.input.value = '';
                  readerRef.current.openImageDialog();
                }
              }}>
              {scanError
                ? 'Unable to detect valid Chirp QR code. Tap to try again.'
                : 'Tap to capture or select picture of QR code.'
              }
            </div>
            // Normal Mode
            : <div className="h2 qr-scanner-overlay-text">
              {scanError
                ? 'Unrecognized Chirp QR code. Please scan again.'
                : 'Scanning for QR code...'}
            </div>
        }
      </div>
    </div>
  );
};

export default QRScanner;

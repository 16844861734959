import React from 'react';
import { formatPhoneNumber } from '../../helpers';

interface SupportPhoneNumberProps {
  supportPhoneNumber?: string | null;
}

const SupportPhoneNumber = ({ supportPhoneNumber }: SupportPhoneNumberProps) => (
  supportPhoneNumber ? (
    <span style={{ display: 'block', marginTop: '24px' }}>
      For help, contact the Leasing Office at{' '}
      <a href={`tel:${supportPhoneNumber}`} style={{ whiteSpace: 'nowrap' }}>
        <strong>{formatPhoneNumber(supportPhoneNumber)}</strong>
      </a>.
    </span>
  ) : null
);

export default SupportPhoneNumber;

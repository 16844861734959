import React from 'react';
import { VirtualDirectoryUser } from '../../apollo';
import warningIcon from '../../assets/icon-warning.svg';
import { getErrorMessage } from '../../helpers';
import { VirtualDirectoryContactRow } from '../VirtualDirectoryContactRow';

type Contact = Pick<VirtualDirectoryUser, 'userId' | 'userDisplayName' | 'unitDisplayName'>;

interface VirtualDirectoryContactListProps {
  accessPointId: string;
  contacts: Contact[];
  error?: Error | null;
}

export const VirtualDirectoryContactList: React.FC<VirtualDirectoryContactListProps> = (props) => {
  const { accessPointId, contacts, error } = props;

  if (contacts.length) {
    return (
      <div className="contact-list">
        {contacts.map(contact =>
          <VirtualDirectoryContactRow
            key={`${contact.userId}-${contact.unitDisplayName}`}
            accessPointId={accessPointId}
            userId={contact.userId}
            contactName={contact.userDisplayName}
            subtext={contact.unitDisplayName}
          />,
        )}
      </div>
    );
  }

  return (
    <div className="contact-list empty-list">
      <div
        className="section1 center-element"
        style={error ? { color: '#f50', textAlign: 'center' } : {}}
      >
        <span className="sectionTitle">
          {error ? (
            <>
              <img
                src={warningIcon}
                alt="Error"
                style={{ display: 'block', margin: '0 auto 10px auto' }}
              />
              {getErrorMessage(error)}
            </>
          ) : 'No results found'}
        </span>
      </div>
    </div>
  );
};

export default VirtualDirectoryContactList;

import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../components';

// Redirect the user from /entry/:accessPointId to /qr/:accessPointId
// Only from the original URL will we renew the user's anonymous token.
// This is a safeguard to prevent users from continuing to use the property
// directory offsite, by requiring them to re-scan the QR code after X minutes
const QrCodeRedirect: React.FC = () => {
  const accessPointId = useParams().accessPointId as string;
  const navigate = useNavigate();

  useEffect(() => {
    if (accessPointId) {
      // Replace to prevent user from returning back to original URL
      navigate(`/qr/${accessPointId}`, { replace: true });
    }
    // eslint-disable-next-line
  }, [accessPointId]);

  return <LoadingSpinner />;
};

export default QrCodeRedirect;

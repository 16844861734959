import { keyframes, stylesheet } from 'typestyle';

const qrModuleAnimationOpen = keyframes({
  from: { top: '100%' },
  to: { top: '0%' },
});

const qrModuleAnimationClose = keyframes({
  from: { top: '0%' },
  to: { top: '100%' },
});

const styles = stylesheet({
  qrCodeScanOpen: {
    animationName: qrModuleAnimationOpen,
    animationDuration: '0.5s',
  },
  qrCodeScanClose: {
    animationName: qrModuleAnimationClose,
    animationDuration: '0.5s',
  },
});

export default styles;

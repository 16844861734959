import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import VerificationInput from 'react-verification-input';

import useLocalStorage from '@rehooks/local-storage';

import { useverifyVendorPinCodeMutation } from '../../apollo';
import { getErrorMessage } from '../../helpers';
import Header from '../Header';
import { LoadingSpinner } from '../LoadingSpinner';
import VendorPinInfoModal from '../VendorPinInfoModal';

interface VendorPinAccessVerificationProps {
  isVirtualDirectoryEnabled: boolean;
  onVerified: () => void;
}

export const VendorPinAccessVerification: React.FC<VendorPinAccessVerificationProps> = (props) => {
  const navigate = useNavigate();
  const accessPointId = useParams().accessPointId as string;
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  // TODO: check with Jamie about keeping - in the verification code
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [, setAnonymousToken] = useLocalStorage<string>('chirpAccessToken');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [verifyVendorPinCode, { loading }] = useverifyVendorPinCodeMutation();

  useEffect(() => {
    const onPaste = (event: ClipboardEvent) => {
      event.preventDefault();

      const pastedCode = event.clipboardData?.getData('text').replace('-', '');

      if (pastedCode?.length === 6) {
        setVerificationCode(pastedCode);
      }
    };

    window.addEventListener('paste', onPaste as EventListener);

    return () => {
      window.removeEventListener('paste', onPaste as EventListener);
    };
  }, []);

  useEffect(() => {
    if (verificationCode && verificationCode.length === 6) {
      const code = `${verificationCode.toUpperCase().substring(0, 3)}-${verificationCode.toUpperCase().substring(3)}`;

      verifyVendorPinCode({
        variables: {
          input: { accessPointId, code },
        },
      }).then(({ data }) => {
        if (data?.verifyVendorPinCode) {
          setAnonymousToken(data.verifyVendorPinCode);
          if (typeof props.onVerified === 'function') {
            props.onVerified();
          }
        }
      }).catch((err) => {
        const error = err as Error;
        setErrorMessage(getErrorMessage(error));
      });
    }
  }, [verificationCode]);

  return (
    <div>
      <Header
        title="Vendor Access PIN"
        leftElement={
          <button className="button-with-icon" onClick={() => navigate(-1)}>
            <img src="/images/arrow-left.png" alt="" className="arrow-icon" />
          </button>
        }
        rightElement={
          <button className="button-with-icon" onClick={() => setInfoModalOpen(true)}>
            <img src="/images/question.svg" alt="" className="info-icon" />
          </button>
        }
        style={{ display: 'flex', flex: 1 }}
      />
      <div className="display-flex-wrapper">
        <div className="verification-wrapper">
          <p className="h2 text-dark" style={{ fontWeight: 600 }}>ENTER VENDOR PIN</p>
          <div className="spacer"/>
          <VerificationInput
            value={verificationCode}
            classNames={{
              container: 'verification-input',
              character: 'code-value',
            }}
            placeholder=""
            onChange={val => setVerificationCode(val.toUpperCase())}
            // this will only work if the code
            // - is alphanumeric without dash
            // - is numberic only with dash
            inputProps={{ autoComplete: 'one-time-code' }}
          />
          {loading && <LoadingSpinner />}
          <div className="spacer"/>
          <div className="spacer"/>
          <span className="error-wrapper">
            {errorMessage}
          </span>
        </div>
        <VendorPinInfoModal
          closeModal={() => setInfoModalOpen(false)}
          isOpen={infoModalOpen}
          isVirtualDirectoryEnabled={props.isVirtualDirectoryEnabled}
        />
      </div>
    </div>
  );
};

export default VendorPinAccessVerification;

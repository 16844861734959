import React from 'react';

interface HeaderProps {
  defaultHeader?: boolean;
  title?: string;
  subtext?: string;
  leftElement?: React.ReactElement;
  rightElement?: React.ReactElement;
  style?: React.CSSProperties;
}

export const Header: React.FunctionComponent<HeaderProps> = ({
  defaultHeader = false,
  title,
  subtext,
  leftElement,
  rightElement,
  style,
}) => {
  if (defaultHeader) {
    return (
      <div className="header">
        <img src="/images/logo-white.png" alt="" className="image-2" />
      </div>
    );
  }

  return (
    <div className="header-wrapper" style={style}>
      {leftElement && <div className="left-header">{leftElement}</div>}
      {/* @TODO: Change to more generic name */}
      <div className={`entry-point-title ${leftElement && rightElement ? 'center-element' : ''}`}>
        <h2 className="h2">{title}</h2>
        <div className="p-white">{subtext}</div>
      </div>
      {rightElement && <div className="right-header">{rightElement}</div>}
    </div>
  );
};

export default Header;

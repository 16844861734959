import { deleteFromStorage } from '@rehooks/local-storage';

export function clearDeprecatedLocalStorage() {
  deleteFromStorage('accessToken');
  deleteFromStorage('userRoles');
  deleteFromStorage('accessKeys');
  deleteFromStorage('acceptedTermsAt');
}

export function clearLocalStorage() {
  clearDeprecatedLocalStorage();
  deleteFromStorage('chirpAccessToken');
  deleteFromStorage('acceptedEulaAt');
}

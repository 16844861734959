import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { AnonymousRoute } from '../components';
import Entry from '../pages/Entry';
import QrCodeRedirect from '../pages/Entry/QrCodeRedirect';
import Guest from '../pages/Guest';
import Home from '../pages/Home';
import Resident from '../pages/Resident';
import VendorAccess from '../pages/VendorAccess';
import VirtualDirectory from '../pages/VirtualDirectory';

export const browserRouter = createBrowserRouter([
  {
    path: '*',
    element: <Home />,
  },
  {
    path: '/entry/:accessPointId',
    element: <AnonymousRoute children={<QrCodeRedirect />} policy="RENEW_TOKEN" />,
  },
  {
    path: '/guest/:userRoleId',
    element: <AnonymousRoute children={<Guest />} policy="GET_TOKEN" />,
  },
  {
    path: '/qr/:accessPointId',
    element: <AnonymousRoute children={<Entry />} policy="REQUIRE_TOKEN" />,
  },
  {
    path: '/resident',
    element: <Resident />,
  },
  {
    path: '/property-directory/:accessPointId',
    element: <AnonymousRoute children={<VirtualDirectory />} policy="REQUIRE_TOKEN" />,
  },
  {
    path: '/vendor/:accessPointId',
    element: <AnonymousRoute children={<VendorAccess />} policy="REQUIRE_TOKEN" />,
  },
]);

import { TimetableRuleState } from '@chirp/access-verification';
import useInterval from '@use-it/interval';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  GuestPassFragment as UserRole,
  useanonymousUserRolesSubscription,
  usegetAccessPointQuery,
} from '../../apollo';
import {
  AccessRequestOptions,
  AlertModal,
  EulaModal,
  Header,
  LoadingSpinner,
  RequestSentDial,
  TapToUnlockDial,
} from '../../components';
import { CHECK_ACCESS_INTERVAL_MS, STRATIS_UNIVERSAL_LINK } from '../../config';
import {
  getAvailabilityDescription,
  getAvailabilityState,
  getErrorMessage,
  getImageUrl,
  isDateAllowedOnTimetable,
} from '../../helpers';
import { useAnonymousUserContext, useEulaModalState } from '../../hooks';

interface ModalState {
  isOpen: boolean;
  isCloseable: boolean;
  content: string | null;
  title: string | null;
}

interface EntryState {
  activeUserRole: UserRole | null | undefined;
  userRoles: UserRole[];
  sentRequest: boolean;
  modalState: ModalState;
}

function selectActiveUserRole(userRoles: UserRole[]) {
  return userRoles.find(({ expiresAt }) => {
    if (!expiresAt) {
      return null;
    }

    return moment(new Date(expiresAt)).isAfter(moment(new Date()));
  });
}

const Entry: React.FC = () => {
  const accessPointId = useParams().accessPointId as string;
  const navigate = useNavigate();

  // Our state is combined into one object so the values can be updated together
  const [state, setState] = useState<EntryState>({
    activeUserRole: null,
    userRoles: [],
    sentRequest: false,
    modalState: {
      isOpen: false,
      isCloseable: true,
      content: null,
      title: null,
    },
  });

  const { activeUserRole, sentRequest, modalState } = state;
  const { anonymousUserId } = useAnonymousUserContext();
  const { acceptedEulaAt, isEulaModalOpen, openEulaModal, closeEulaModal } = useEulaModalState();

  const { data: accessPointsData } = usegetAccessPointQuery({
    skip: !accessPointId,
    variables: { accessPointId },
    onError: (err) => {
      setState(prevState => ({
        ...prevState,
        modalState: {
          isOpen: true,
          isCloseable: false,
          title: 'Unable to access entry point',
          content: getErrorMessage(err),
        },
      }));
    },
  });

  const accessPoint = accessPointsData ? accessPointsData.accessPoint : null;
  const propertyId = accessPoint ? accessPoint.propertyId : null;

  const propertyFeatureFlags = accessPoint?.property?.featureFlags || [];
  // @TODO: Use strong typing for feature flag keys
  const isStratisProperty = propertyFeatureFlags.includes('COMMUNITY_CONNECT');
  const isVirtualDirectoryEnabled = propertyFeatureFlags.includes('VIRTUAL_DIRECTORY');

  useEffect(() => {
    if (accessPoint?.accessPointId && isStratisProperty) {
      // Redirect guest/visitor scanning smart sign with Chirp URL to the STRATIS web app
      const newQrCodeUrl = `${STRATIS_UNIVERSAL_LINK}/chirp/entry/${accessPoint.accessPointId}`;

      window.location.replace(newQrCodeUrl);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessPoint?.accessPointId, isStratisProperty]);

  useanonymousUserRolesSubscription({
    skip: !anonymousUserId || !accessPoint || isStratisProperty,
    onSubscriptionData: ({ subscriptionData }) => {
      const approvedGuestPasses = (subscriptionData.data
        ? subscriptionData.data.anonymousUserRoles
        : []).filter(g => g.scopedPropertyId === propertyId);

      if (approvedGuestPasses) {
        setState(prevState => ({
          ...prevState,
          activeUserRole: selectActiveUserRole(approvedGuestPasses),
          userRoles: approvedGuestPasses,
        }));
      }
    },
  });

  // Check every interval whether the user still has an active user role
  useInterval(() => {
    setState((prevState) => {
      const nextActiveUserRole = selectActiveUserRole(prevState.userRoles);

      const accessExpired = Boolean(prevState.activeUserRole && !nextActiveUserRole);
      const nextModalState: ModalState = accessExpired
        ? {
          isOpen: true,
          isCloseable: true,
          title: 'Access Expired',
          content: 'Your guest access has expired. Please send another request for access.',
        }
        : prevState.modalState;

      return {
        ...prevState,
        activeUserRole: nextActiveUserRole,
        // When access token expires, the "Request Sent" should not be shown
        sentRequest: nextActiveUserRole ? false : prevState.sentRequest,
        modalState: nextModalState,
      };
    });
  }, CHECK_ACCESS_INTERVAL_MS);

  const alertModal = modalState.isOpen ? (
    <AlertModal
      {...modalState}
      closeModal={() => {
        if (modalState.isCloseable) {
          setState({
            ...state,
            modalState: {
              ...modalState,
              isOpen: false,
            },
          });
        }
      }}
    />
  ) : null;

  if (!modalState.isCloseable) {
    // Users won't be able to progress with these errors. So nothing else should be rendered.
    return alertModal;
  }

  if (accessPoint && !isStratisProperty) {
    const { name, image, property, publicAccessPointRoles } = accessPoint;
    const { timezone } = property;
    const { GUEST_PROPERTY_KEY: guestRole, VENDOR_PIN: vendorPinRole } = publicAccessPointRoles;
    const { schedule } = guestRole;

    const accessTimeout = accessPoint.accessTimeout || 5000;
    const { name: propertyName, supportPhoneNumber } = property;
    const cloudId = image ? image.cloudId : null;

    const accessTime = new Date();
    let isAccessPointAvailable = true;

    if (!isDateAllowedOnTimetable(accessTime, schedule, timezone)) {
      isAccessPointAvailable = false;
    }

    const availability = getAvailabilityDescription(accessTime, schedule, timezone);
    const imageUrl = getImageUrl(cloudId, { width: 750, crop: 'scale' });
    const availabilityState = getAvailabilityState(accessTime, schedule, timezone);

    return (
      <div>
        <Header title={name} subtext={propertyName} />
        {imageUrl && (
          <div className="entry-point-image">
            <img
              src={imageUrl}
              sizes="(max-width: 750px) 100vw, 750px"
              alt={propertyName}
            />
          </div>
        )}
        {activeUserRole && activeUserRole.expiresAt && (
          <TapToUnlockDial
            accessPointId={accessPointId}
            accessTimeout={accessTimeout}
            isAvailable={isAccessPointAvailable}
            hasAccess={isAccessPointAvailable}
            isAutomaticallyUnlocked={availabilityState === TimetableRuleState.UNLOCKED}
            availability={availability}
            bottomContent={
              <div className="content-bottom">
                <img src="/images/icon-alert.png" alt="" className="icon-bottom" />
                <div className="p text-dark text-bold">
                  Request approved! You can access entry points until{' '}
                  {moment(activeUserRole.expiresAt).format('h:mm A')}.
                </div>
              </div>
            }
          />
        )}
        {!activeUserRole && sentRequest && (
          <RequestSentDial />
        )}
        {!activeUserRole && !sentRequest && anonymousUserId && (
          <>
            {guestRole.hasAccess && (
              <AccessRequestOptions
                accessPointId={accessPointId}
                accessPointName={name}
                anonymousUserId={anonymousUserId}
                onShareSuccess={() => setState({
                  ...state,
                  sentRequest: true,
                })}
                propertyName={propertyName}
                supportPhoneNumber={supportPhoneNumber}
                isAvailable={isAccessPointAvailable}
                availability={availability}
                isVirtualDirectoryEnabled={isVirtualDirectoryEnabled}
              />
            )}
            {guestRole.hasAccess && isVirtualDirectoryEnabled &&
              <>
                <div style={{ marginBottom: '15px' }}></div>
                <Link
                  onClick={(e) => {
                    if (!acceptedEulaAt) {
                      e.preventDefault();
                      openEulaModal();
                    }
                  }}
                  to={`/property-directory/${accessPoint.accessPointId}`}
                  className="blue-button w-inline-block"
                >
                  <span
                    className="display-flex-wrapper"
                    style={{ alignItems: 'flex-start', textAlign: 'left' }}
                  >
                    <h1 className="h2">Property Directory</h1>
                    <span className="p text-white">
                      Request access by selecting a contact from the directory
                    </span>
                  </span>
                  <img src="/images/search-white.png" alt="" className="icon-20px" />
                </Link>
                <EulaModal
                  isOpen={isEulaModalOpen}
                  closeModal={closeEulaModal}
                  onAcceptEula={() => {
                    navigate(`/property-directory/${accessPoint.accessPointId}`);
                  }}
                />
              </>
            }
            {vendorPinRole.hasAccess &&
              <>
                <div style={{ marginBottom: '15px' }}></div>
                <Link
                  onClick={(e) => {
                    if (!acceptedEulaAt) {
                      e.preventDefault();
                      openEulaModal();
                    }
                  }}
                  to={`/vendor/${accessPoint.accessPointId}`}
                  className="blue-button w-inline-block"
                >
                  <span
                    className="display-flex-wrapper"
                    style={{ alignItems: 'flex-start', textAlign: 'left' }}
                  >
                    <h1 className="h2">Vendor PIN</h1>
                    <span className="p text-white">
                      Enter your Vendor PIN code for access
                    </span>
                  </span>
                  <span className="p text-white" style={{ fontSize: 30 }}>#</span>
                </Link>
                <EulaModal
                  isOpen={isEulaModalOpen}
                  closeModal={closeEulaModal}
                  onAcceptEula={() => {
                    navigate(`/vendor/${accessPoint.accessPointId}`);
                  }}
                />
              </>
            }
            <div className="content" style={{ paddingBottom: '30px', alignItems: 'center' }}>
              <Link to="/resident" style={{ textDecoration: 'underline' }}>
                <span>I&#x27;m a Resident</span>
              </Link>
            </div>
          </>
        )}
        {alertModal}
      </div>
    );
  }

  return <LoadingSpinner />;
};

export default Entry;

import React, { useEffect } from 'react';
import Timecode from 'react-timecode';
import { useStopwatch } from 'react-timer-hook';
import { CallState } from '../VirtualDirectoryContactRow';

interface CallScreenProps {
  onEndCall: () => void;
  onDigitPress: (digit: string) => void;
  name: string;
  callState: CallState;
}

const CallScreen: React.FC<CallScreenProps> = ({
  name,
  onEndCall,
  onDigitPress,
  callState,
}) => {
  const { seconds } = useStopwatch({ autoStart: true });

  useEffect(() => {
    document.body.classList.add('preventScroll');

    return () => {
      document.body.classList.remove('preventScroll');
    };
  }, []);

  const isRecordingDisabled = () => {
    return callState !== 'INITIATED' && callState !== 'CONNECTED';
  };

  const isStopRecordingDisabled = () => {
    return callState !== 'RECORDING';
  };

  return (
    <div className="call-in-process-layout">
      <div className="call-in-process-background"></div>
      <div className="display-flex-wrapper callscreen-container">
        <div className="timer-wrapper">
          <div className="resident-name"><b>{name}</b></div>
          <div className={`timer ${callState === 'ON_HOLD' ? 'hide' : ''}`}>
            <Timecode time={seconds * 1000} />
          </div>
          <div className={`timer ${callState === 'ON_HOLD' ? '' : 'hide'}`}>
            Calling...
          </div>
        </div>
        <div className="row display-flex-wrapper">
          <div className={isRecordingDisabled() ? 'disabled-callscreen-action-button' : ''}>
            <button
              disabled={isRecordingDisabled()}
              className="callscreen-button"
              onClick={() => onDigitPress('1')}
            >
              <img src="/images/microphone.svg" alt="Record" style={{ width: 25 }} />
            </button>
            <div>Record</div>
          </div>
          <div className={isStopRecordingDisabled() ? 'disabled-callscreen-action-button' : ''}>
            <button
              disabled={isStopRecordingDisabled()}
              className="callscreen-button"
              onClick={() => onDigitPress('*')}
            >
              <img src="/images/stop.svg" alt="Stop Record" style={{ width: 20 }} />
            </button>
            <div>Stop Record</div>
          </div>
        </div>
        <div className="row">
          <button
            className="callscreen-button end-call"
            onClick={() => onEndCall()}
          >
            <img src="/images/phone-white.png" alt="" style={{ width: 35 }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CallScreen;

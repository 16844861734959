import { isDateAllowedOnTimetable } from '@chirp/access-verification';
import { PropertyFragment } from '../../apollo';

export function getIsLeasingOfficeClosed(property?: PropertyFragment | null) {
  const virtualDirectoryStaffHours = property?.virtualDirectoryStaffHours;
  const timezone = property?.timezone;

  return (
    !!virtualDirectoryStaffHours &&
    !!timezone &&
    !isDateAllowedOnTimetable(new Date(), virtualDirectoryStaffHours, timezone)
  );
}

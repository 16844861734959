// tslint:disable max-line-length
const env = process.env;

export const ACCESS_REQUEST_EXPIRATION_MINUTES = +(env.ACCESS_REQUEST_EXPIRATION_MINUTES || 15);
export const CHECK_ACCESS_INTERVAL_MS = +(env.CHECK_ACCESS_INTERVAL_MS || 1000);
export const CHIRP_APP_DOWNLOAD_ANDROID_URL = 'https://play.google.com/store/apps/details?id=com.chirp.access';
export const CHIRP_APP_DOWNLOAD_IOS_URL = 'https://apps.apple.com/us/app/chirp-access/id1450159493';
export const CHIRP_APP_DOWNLOAD_MARKETING_URL = 'https://www.chirpsystems.com/app';
export const CHIRP_MARKETING_URL = 'https://www.chirpsystems.com';
export const CHIRP_UNIVERSAL_LINK = String(env.REACT_APP_CHIRP_UNIVERSAL_LINK);
export const ENGRAIN_API_KEY = String(env.REACT_APP_ENGRAIN_API_KEY);
export const GRAPHQL_SERVER_SUBSCRIPTION_URL = String(env.REACT_APP_GRAPHQL_SERVER_SUBSCRIPTION_URL);
export const GRAPHQL_SERVER_URL = String(env.REACT_APP_GRAPHQL_SERVER_URL);
export const REALPAGE_EULA_URL = 'https://www.realpage.com/smart-building-eula/';
export const REALPAGE_PATENT_MARKING = 'https://www.realpage.com/legal/patent-marking/';
export const REALPAGE_PRIVACY_POLICY_URL = 'https://www.realpage.com/privacy-policy/';
export const STRATIS_UNIVERSAL_LINK = String(env.REACT_APP_STRATIS_UNIVERSAL_LINK);

import React from 'react';

interface DialTextProps {
  header?: string;
  text: string;
  subtext?: string;
  textStyle?: any;
  subtextStyle?: any;
}

const DialText: React.FunctionComponent<DialTextProps> = ({
  header,
  text,
  subtext,
  textStyle,
  subtextStyle,
}) => {
  return (
    <div className="dial-text-component">
      {header &&
        <div className="dial-text-header">
          <h2 className="h2">
            {header}
          </h2>
        </div>
      }
      <div className="dial-text-inside">
        <div className="h2" style={textStyle}>
          {text}
        </div>
        <div className="p text-white" style={subtextStyle}>
          {subtext}
        </div>
      </div>
    </div>
  );
};

export default DialText;

import { stylesheet } from 'typestyle';

const styles = stylesheet({
  buttonIcon: {
    display: 'flex',
    width: '60px',
    height: '60px',
    background: 'white',
    border: '1px solid white',
    boxSizing: 'border-box',
    borderRadius: '50px',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;

import {
  filterTimetable,
  formatStartAndEndTime,
  getCurrentTimetableRules,
  getTimezonedDate,
  getValidTimetableRulesByDay,
  isDateAllowedOnTimetable,
  TimetableRule,
  TimetableRuleState,
  TimetableRuleType,
} from '@chirp/access-verification';

export { isDateAllowedOnTimetable };

export function getAvailabilityDescription(
  date = new Date(),
  timetable: TimetableRule[] | null | undefined,
  timezone: string,
) {
  const timezonedDate = getTimezonedDate(date, timezone);
  const filteredTimetable = filterTimetable(timezonedDate, timetable, timezone);

  if (!filteredTimetable) {
    return '';
  }

  const currentTimetableRules = getCurrentTimetableRules(timezonedDate, filteredTimetable);

  if (currentTimetableRules.some(rule => rule.type === TimetableRuleType.RESTRICTION)) {
    return '';
  }

  const validTimetableRules = getValidTimetableRulesByDay(timezonedDate, filteredTimetable);

  if (validTimetableRules.length > 0) {
    const [timetableRule] = validTimetableRules;

    if (timetableRule.start === '00:00' && timetableRule.end === '00:00') {
      return '';
    }

    const { startTime, endTime } = formatStartAndEndTime(timezonedDate, timetableRule);

    return `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')}`;
  }

  return '';
}

export function getAvailabilityState(
  date = new Date(),
  timetable: TimetableRule[] | null | undefined,
  timezone: string,
) {
  const timezonedDate = getTimezonedDate(date, timezone);
  const filteredTimetable = filterTimetable(timezonedDate, timetable, timezone);

  if (!filteredTimetable) {
    return TimetableRuleState.CONTROLLED;
  }

  const currentTimetableRules = getCurrentTimetableRules(timezonedDate, filteredTimetable);

  if (currentTimetableRules.some(r => r.state === TimetableRuleState.LOCKDOWN)) {
    return TimetableRuleState.LOCKDOWN; // LOCkDOWN has highest priority
  }

  if (currentTimetableRules.some(r => r.state === TimetableRuleState.UNLOCKED)) {
    return TimetableRuleState.UNLOCKED; // UNLOCKED has second highest priority
  }

  const state = currentTimetableRules[0] ? currentTimetableRules[0].state : null;

  return state || TimetableRuleState.CONTROLLED;
}
